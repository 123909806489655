import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import { useLobby } from './useLobby';
import { Button } from '../../components/Button';

import styles from './Lobby.module.scss';
import logoImg from "../../images/logo.png";

function Lobby({ startGame }) {
  const { t } = useTranslation()
  const { tooManyRequests } = useDataContext()
  const { loading, handleNewGame } = useLobby({ startGame });

  return (
    <div className={styles.wrapper}>
      <div className={styles.lobby}>
        <img src={logoImg} alt='logo' className={styles.lobby__logo} />
        <h1 className={styles.lobby__title}>Cheat The AI!</h1>
        <div className={styles.lobby__buttons}>
          {loading ?
            <Button disabled>{t("Loading")}...</Button>
            :
            <>
              <Link to='/practice'>
                <Button style={{ background: 'linear-gradient(0deg, rgb(156 156 156) 0%, rgb(105 105 105) 50%, rgb(74 74 74) 100%)' }} block>{t("Practice Mode")}</Button>
              </Link>
              <Button onClick={handleNewGame} disabled={tooManyRequests}>{t("New Game")}</Button>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export { Lobby };
