import React, { useState, useEffect } from 'react'

export const ScalableComponent = ({ children, value, maxScale = 1, origin = 'center', style, className }) => {
  const [scale, setScale] = useState(Math.min(window.innerHeight / value, maxScale));
  const updateSize = () => setScale(Math.min(window.innerHeight / value, maxScale));

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return (
    <div className={className} style={{ transform: `scale(${scale})`, transformOrigin: origin, width: '100%', ...style }}>
      {children}
    </div>
  )
}
