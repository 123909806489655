import i18n from "i18next";

export const useLang = () => {

    const refreshLanguage = () => {
        if (/^es.*$/.test(i18n.language.toLocaleLowerCase()))
            changeLanguage('es');
        else
            changeLanguage('en');
    }

    const changeLanguage = lang => {
        i18n.changeLanguage(lang)
    }

    return { refreshLanguage, changeLanguage }
}
