import React from 'react'
import { useTranslation } from 'react-i18next';
import { Layout } from '../../layouts'
import styles from "./About.module.scss";

export const About = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <div className='app-container'>
        <h1 className='app-title'>{t("About")}</h1>

        <div className={`app-card ${styles.about}`} dangerouslySetInnerHTML={{ __html: t("About Content") }} style={{ lineHeight: '1.4rem' }}>
        </div>
      </div>
    </Layout>
  )
}
