import React, { useRef, useState } from 'react'
import styles from './Tooltip.module.scss'

export const Tooltip = ({ text, children }) => {
  const el = useRef(null)
  const [show, setShow] = useState(false)
  const [pos, setPos] = useState({ x: 0, y: 0 })

  const textStyles = {
    left: pos.x,
    top: pos.y,
  }

  const handleHover = () => {
    setShow(true)
    const { x, y } = el.current.getBoundingClientRect()
    setPos({ x, y });
  }

  const handleOut = () => {
    setShow(false)
  }

  return (
    <div ref={el} onMouseEnter={handleHover} onMouseLeave={handleOut} className={styles.tooltip}>
      {children}
      <span className={`app-card ${styles.tooltip__text} ${!show && styles['tooltip__text--hidden']}`} style={textStyles}>{text}</span>
    </div>
  )
}
