import { useEffect, useRef, useState } from 'react'

export const usePlayback = () => {
    const audio = useRef(null)
    const [isPlaying, setPlaying] = useState(false)

    useEffect(() => {
        return () => {
            stop();
        }
    }, [])


    const playByUrl = async ({ url, isBase64 = false }) => {
        stop()

        audio.current = document.createElement('audio')
        var sourceElement = document.createElement('source')
        audio.current.appendChild(sourceElement)

        if (isBase64) {
            const res = await fetch("data:audio/ogg;base64," + url)
            const blob = await res.blob()
            const audioURL = window.URL.createObjectURL(blob);
            sourceElement.src = audioURL
        } else {
            sourceElement.src = url
        }
        sourceElement.type = 'audio/mp3' // or whatever

        audio.current.load()
        audio.current.onended = clearAudio
        audio.current.onerror = clearAudio
        audio.current.play()

        setPlaying(true)
    }

    const stop = () => {
        if (audio.current) {
            audio.current.pause();
            audio.current.currentTime = 0;
        }
        clearAudio()
    }

    const clearAudio = () => {
        audio.current = null
        setPlaying(false)
    }

    return { isPlaying, playByUrl, stop }
}
