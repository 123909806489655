import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePlayback } from '../../hooks/usePlayback';
import Header from './Header';
import { ScalableComponent } from '../../components/ScalableComponent/ScalableComponent';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { ShareRoundButton } from '../../components/ShareRoundButton/ShareRoundButton';
import { soundClasses } from '../../categories';

import Play from '../../images/play.png';
import ShareIcon from './assets/share_icon.png';

function Prediction({
  soundCategory,
  soundClass,
  audioURL,
  prediction,
  nextStep,
  goToRecord,
  attempts,
  isPractice = false,
}) {
  const { t, i18n } = useTranslation();
  const { isPlaying, playByUrl } = usePlayback();

  const win = prediction.result === soundClass.class;
  const predictionClass = soundClasses.find(
    (c) => c.class === prediction.result
  ) || {
    class: 'error',
    label: {
      en: '???',
      es: '???',
    },
    emoji: '🤔',
  };
  const score = prediction.score;
  const barLabels = [
    { name: t('Bad'), value: 0, align: 'right', color: '#626262' },
    { name: t('Excellent'), value: 1, align: 'left', color: '#6fbf27' },
  ];

  const handleFinishRound = () => {
    nextStep({ score });
  };

  const handleRetry = () => {
    goToRecord({ soundClass, soundCategory, isARetry: true });
  };

  return (
    <>
      {!isPractice && (
        <Header
          title={soundCategory.label[i18n.language]}
          color={soundCategory.color}
        />
      )}
      <div className="wrapper">
        <ScalableComponent value={800}>
          <div className="record" style={{ transform: 'translateY(-8vh)' }}>
            {win && (
              <div className="record__score-container">
                <div className="record__title">
                  <span>{t('Great')}!</span>
                </div>
                {t('It sounds like a')}
                <br />
                <div className="record__predicted-class">
                  <span>{predictionClass?.label?.[i18n.language]}</span>
                </div>
                <div className="record__score">{score}pts</div>
                <ProgressBar
                  value={prediction.confidence_score}
                  height="48"
                  labels={barLabels}
                  color="#6fbf27"
                  className="record__bar"
                />
              </div>
            )}
            {!win && (
              <div className="record__score-container">
                <div className="record__title">
                  <span>{t('Oh no!')}!</span>
                </div>
                {t('It sounds like a')}
                <br />
                <div className="record__predicted-class">
                  <span>{predictionClass?.label?.[i18n.language]}</span>
                </div>
                <div className="record__score record__score--wrong">0pts</div>
                <ProgressBar
                  value={0}
                  height="48"
                  labels={barLabels}
                  color="#a5a5a5"
                  className="record__bar"
                />
              </div>
            )}
            <div className="record__buttons">
              {isPractice ? (
                <button
                  className="record__finish-round record__finish-round--retry"
                  onClick={handleRetry}
                >
                  {t('Retry')}
                </button>
              ) : (
                <>
                  {!win && attempts >= 1 && (
                    <button
                      className="record__finish-round record__finish-round--retry"
                      onClick={handleRetry}
                    >
                      {`${t('Retry')} (${attempts})`}
                    </button>
                  )}
                  <button
                    className="record__finish-round"
                    onClick={handleFinishRound}
                  >
                    {t('Continue')}
                  </button>
                </>
              )}
            </div>

            {!isPractice && (
              <ShareRoundButton
                sound={soundClass.label[i18n.language]}
                idRound={prediction.id_round}
              >
                <button className="record__finish-round record__finish-round--share">
                  <img src={ShareIcon} alt="share" />
                  {t('Share')}
                </button>
              </ShareRoundButton>
            )}

            <div className="playback">
              <button
                className={`playback__button ${
                  isPlaying && 'playback__button--disabled'
                }`}
                onClick={() => playByUrl({ url: audioURL })}
              >
                <img className="playback__icon" src={Play} alt="play" />
              </button>
            </div>
          </div>
        </ScalableComponent>
      </div>
    </>
  );
}

export { Prediction };
