import React from 'react'
import styles from './Button.module.scss'

export const Button = ({ type = 'button', small = false, disabled = false, block = false, icon = false, onClick, style, className, children }) => {
  const classes = [
    small && styles['button--small'],
    disabled && styles['button--disabled'],
    block && styles['button--block'],
    icon && styles['button--icon'],
  ]

  return (
    <button onClick={onClick} type={type} className={`${styles.button} ${classes.join(" ")} ${className}`} style={style}>{children}</button>
  )
}
