import React, { useEffect, useState } from 'react'
import styles from './ProgressBar.module.css'

export const ProgressBar = ({ value: newValue, text, height = 48, color = '#6fbf27', labels = [], className }) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setValue(newValue)
    }, 100);
  }, [newValue])

  return (
    <div className={`${styles['progress']} ${className}`} style={{ '--height': `${height}px`, '--color': color }}>
      <div className={styles['progress__bar']} style={{ '--width': `${value * 100}%` }}></div>
      <span className={styles['progress__text']}>{text ?? `${Math.round(value * 100)}%`}</span>

      {labels.map(({ name, value, align, color }) =>
        <div className={styles['label']} style={{ '--color': color, '--position-x': `${value * 100}%` }} key={name}>
          <span className={styles['label__text']} style={{ '--translate-x': align === 'center' ? '-50%' : align === 'left' ? '-100%' : '0%' }}>{name}</span>
        </div>
      )}
    </div>
  )
}
