export default {
  "Sign Out": "Cerrar Sesión",
  "Sign In": "Iniciar Sesión",
  "Login": "Iniciar Sesión",
  "Sign up for free": "Regístrate gratis",
  "New to the game": "New to the game",
  "Email": "Correo",
  "Password": "Contraseña",
  "Register": "Registro",
  "Confirm Password": "Confirmar Contraseña",
  "Create Account": "Crear cuenta",
  "Register Info": `Al proceder, acepto los <a href='https://www.cogniflow.ai/terms' target='_blank'>Términos de Servicio</a> de Cogniflow y reconozco la <a href='https://www.cogniflow.ai/privacy-policy' target='_blank'>Política de Privacidad</a>.`,
  "Already have an account?": "¿Ya tienes una cuenta?",

  "Practice Mode": "Modo Práctica",
  "New Game": "Nuevo Juego",
  "Loading": "Cargando",

  "Leaderboard": "Tabla de clasificación",
  "Sound": "Sonido",
  "You": "Tú",
  "Unknown": "Desconocido",
  "No records yet": "Aún no hay registros",
  "Be the first to compete": "Sé el primero en competir",

  "Spin": "Girar",
  "Try to imitate the sound of a": "Intenta imitar el sonido de un(a)",
  "Great": "Genial",
  "Oh no": "Oh no",
  "It sounds like a": "Suena como un(a)",
  "Continue": "Continuar",
  "Retry": "Reintentar",
  "Share": "Compartir",
  "Bad": "Mal",
  "Excellent": "Excelente",
  "Game Over": "Fin del juego",
  "Play Again": "Volver a jugar",
  "Recorder Error": "¿No funciona la grabación? Por favor, compruebe",
  "here": "aquí",

  "Play a random sound of": "Reproducir un sonido aleatorio de",

  "Share Text": "Escúchame imitando a un(a) $1 mientras juego a Cheat The AI! (powered by Cogniflow AI). ¡Regístrate gratis y te reto a que superes mi puntuación!",
  "Round Shared Text": "Este soy yo imitando a un(a) $1 mientras juego",
  "Play": "Jugar",

  "About": "Acerca de",
  "About Content": `
    <p>El juego CHEAT THE AI! (anteriormente, cheat me now!) fue propuesto por <strong>Cogniflow</strong> como parte de un hackaton (Edición PandemIA, Marzo 2021) donde Cogniflow fue puesto a prueba por muchos desarrolladores sin ningún conocimiento en el campo de la IA durante dos días.</p>

    <p>En este hackaton, <strong><a href='https://www.linkedin.com/in/andrearosr/' target='_blank'>Andrea Rosales</a></strong> (desarrolladora) y <strong><a href='https://www.linkedin.com/in/maria-camila-suarez-824977149/' target='_blank'>María Camila Suarez</a></strong> (diseñadora) se asociaron para realizar la primera versión del juego propuesto con la ayuda del backend de Inteligencia Artificial de Cogniflow.</p>

    <p>Puede leer el artículo completo sobre el hackaton <strong><a href='https://uruit.com/blog/artificial-intelligence-hackathon/' target='_blank'>aquí</a></strong>.</p>

    <p>El modelo de IA para el juego ha sido entrenado utilizando un subconjunto del conjunto de datos ESC-50 e incluye las siguientes categorías y sonidos:</p>

    <ul>
      <li><strong>Animales:</strong> gatos, perros, vacas, gallinas y gallos.</li>
      <li><strong>Humanos:</strong> estornudos, ronquidos y llantos de bebés.</li>
      <li><strong>Doméstico:</strong> el clic de un ratón y el segundero de un reloj.</li>
      <li><strong>Urbano:</strong> helicóptero, motosierra y sirena.</li>
      <li><strong>Naturaleza:</strong> viento, gotas de agua y pájaros cantando.</li>
    </ul>

    <p>Una segunda versión refinada del juego que añade nuevas características interesantes como el modo práctica y competición, tablas de clasificación globales y por sonido, y la posibilidad de compartir en redes sociales (entre otras) fue extendido por el equipo de Congliflow para mostrar el poder de los backends de IA soportando aplicaciones inteligentes en cualquier industria y sector (en este caso juegos y entretenimiento).</p>

    <p><strong>El equipo de Cogniflow.</strong></p>`,
    
  "You must specify an email": "Debe especificar un correo electrónico",
  "This is not a valid email": "Este no es un correo electrónico válido",
  "You must specify a nick": "Debe especificar un nick",
  "You must specify a password": "Debe especificar una contraseña",
  "Password must contain": "La contraseña debe contener al menos 8 caracteres, incluyendo letras mayúsculas y minúsculas y números",
  "You must confirm above password": "Debe confirmar la contraseña anterior",
  "Password must match": "La contraseña debe coincidir",
}