import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import { ScalableComponent } from '../../components/ScalableComponent/ScalableComponent';
import styles from './GameOver.module.css';

function GameOver({ score, nextStep }) {
  const { t } = useTranslation();
  const { setIdGame } = useDataContext();

  useEffect(() => {
    setIdGame('');
  }, []);

  return (
    <div className="wrapper">
      <ScalableComponent value={800}>
        <div className="record">
          <h1 className={styles.wrapper__title}>{t('Game Over')}</h1>
          <div className={styles.wrapper__score}>{`${score} pts`}</div>
          <button onClick={nextStep} className={styles.wrapper__button}>
            {t('Play Again')}
          </button>
        </div>
      </ScalableComponent>
    </div>
  );
}

export { GameOver };
