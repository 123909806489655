import React from 'react';
import ReactDOM from 'react-dom';
import './i18'

import { DataProvider } from './context/DataContext';
import { AuthProvider } from './context/AuthContext';
import { GameProvider } from './context/GameContext';
import App from './App';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <DataProvider>
      <AuthProvider>
        <GameProvider>
          <App />
        </GameProvider>
      </AuthProvider>
    </DataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
