import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDataContext } from '../../context/DataContext';
import { useAuthContext } from '../../context/AuthContext';
import { useClickOutside } from '../../hooks/useClickOutside';

export const useHeader = ({ avatarRef }) => {
    const navigate = useNavigate();
    const { reset } = useDataContext()
    const { user, isAuthenticated, logout } = useAuthContext()
    const [showMenu, setMenu] = useState(false)

    useEffect(() => {
        setMenu(false)
    }, [isAuthenticated])

    useClickOutside(avatarRef, () => setMenu(false))

    const handleAvatarClick = () => setMenu(!showMenu)

    const handleLogout = () => {
        logout()
        reset()
        navigate('/login')
    }

    return { isAuthenticated, showMenu, user, handleAvatarClick, handleLogout }
}
