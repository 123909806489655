import React, { useEffect, useState } from 'react';
import { useDataContext } from '../../context/DataContext';
import { useGameContext } from '../../context/GameContext';
import { SpinWheel } from './SpinWheel';
import { Recorder } from './Recorder';
import { Loading } from './Loading';
import { Prediction } from './Prediction';
import { GameOver } from './GameOver';
import './game.css';

const MAX_ATTEMPTS = 2
const MAX_ROUNDS = 5

export const Game = ({ soundClass, soundCategory, isPractice = false }) => {
  const { setState } = useDataContext()
  const { setGameStep: setGameStepCtx } = useGameContext()
  const [step, setStep] = useState(1)
  const [recordClass, setRecordClass] = useState(null);
  const [recordCategory, setRecordCategory] = useState(null);
  const [recordBlob, setRecordBlob] = useState(null);
  const [recordURL, setRecordURL] = useState(null);
  const [recordPrediction, setRecordPrediction] = useState(null);
  const [round, setRound] = useState(1);
  const [coins, setCoins] = useState(0);
  const [attempts, setAttemps] = useState(MAX_ATTEMPTS);
  const [soundsUsed, setSoundsUsed] = useState([])

  const setGameStep = value => {
    setStep(value)
    setGameStepCtx(value)
  }

  useEffect(() => {
    if (isPractice) {
      setRecordClass(soundClass)
      setRecordCategory(soundCategory)
      setGameStep(2)
    } else {
      setGameStep(1)
    }
  }, [])

  const goToRecord = ({ soundClass, soundCategory, isARetry = false }) => {
    setSoundsUsed(prev => [...prev, soundClass.class]);
    setRecordClass(soundClass);
    setRecordCategory(soundCategory);
    if (isARetry) setAttemps(prev => prev - 1)
    setGameStep(2);
  }

  const goToPredict = ({ audioBlob, audioURL }) => {
    setRecordBlob(audioBlob);
    setRecordURL(audioURL);
    setGameStep(3);
  }

  const goToResult = ({ prediction }) => {
    setRecordPrediction(prediction);
    setGameStep(4);
  }

  const goToWheelOrGameOver = ({ score }) => {
    setCoins(coins + score);
    setAttemps(MAX_ATTEMPTS);

    const newRound = round + 1
    if (newRound <= MAX_ROUNDS) {
      setRound(newRound);
      setGameStep(1);
    } else {
      setGameStep(5);
    }
  }

  const goToLobby = () => {
    setState('lobby');
  }

  return (
    <>
      {step === 1 && <SpinWheel round={round} maxRounds={MAX_ROUNDS} coins={coins} soundsUsed={soundsUsed} nextStep={goToRecord} />}
      {step === 2 && <Recorder soundClass={recordClass} soundCategory={recordCategory} nextStep={goToPredict} isPractice={isPractice} />}
      {step === 3 && <Loading audioBlob={recordBlob} soundCategory={recordCategory} soundClass={recordClass} attempt={MAX_ATTEMPTS - attempts + 1} nextStep={goToResult} isPractice={isPractice} />}
      {step === 4 && <Prediction audioURL={recordURL} soundCategory={recordCategory} soundClass={recordClass} prediction={recordPrediction} nextStep={goToWheelOrGameOver} goToRecord={goToRecord} attempts={attempts} isPractice={isPractice} />}
      {step === 5 && <GameOver score={coins} nextStep={goToLobby} />}
    </>
  );
}
