import { useEffect, useState } from 'react'
import { http } from '../../services/http'

export const useLeaderboard = () => {
  const [leaderboardToShow, setLeaderboardToShow] = useState('general')
  const [generalRanking, setGeneralRanking] = useState([])
  const [soundRanking, setSoundRanking] = useState([])
  const [selectedSound, setSelectedSound] = useState('crying')
  const [loadingGeneralRanking, setLoadingGeneralRanking] = useState(false)
  const [loadingSoundRanking, setLoadingSoundRanking] = useState(false)

  useEffect(() => {
    if (leaderboardToShow === 'general') fetchLeaderboardGeneral()
    if (leaderboardToShow === 'sound') fetchLeaderboardSound(selectedSound)
  }, [leaderboardToShow, selectedSound])

  const fetchLeaderboardGeneral = async () => {
    setLoadingGeneralRanking(true)
    setGeneralRanking([])
    try {
      const { ranking } = await http.get('leaderboard/general')
      setGeneralRanking(ranking)
    } catch (error) { }
    setLoadingGeneralRanking(false)
  }

  const fetchLeaderboardSound = async (selectedSound) => {
    setLoadingSoundRanking(true)
    setSoundRanking([])
    try {
      const { ranking } = await http.get(`leaderboard/sound/${selectedSound}`)
      setSoundRanking(ranking)
    } catch (error) { }
    setLoadingSoundRanking(false)
  }

  return { leaderboardToShow, setLeaderboardToShow, generalRanking, soundRanking, selectedSound, setSelectedSound, loadingGeneralRanking, loadingSoundRanking }
}
