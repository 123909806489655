import React from 'react'
import { NavLink } from "react-router-dom";
import { useFooter } from './useFooter';
import { useFullscreen } from '../../hooks/useFullscreen';

import styles from "./Footer.module.scss";
import crownImg from './assets/crown.png'
import homeImg from './assets/home.png'
import infoImg from './assets/info.png'
import fullscreenImg from './assets/fullscreen.png'

function Footer() {
  const { validateNavigation } = useFooter()
  const { toggleFullScreen } = useFullscreen()

  return (
    <footer className={styles.footer}>
      <NavLink
        to='/leaderboard'
        onClick={validateNavigation}
        className={navData => `${styles.footer__btn} ${navData.isActive && styles['footer__btn--active']}`}>
        <img src={crownImg} alt='leaderboard' />
      </NavLink>

      <NavLink
        to='/'
        onClick={validateNavigation}
        className={navData => `${styles.home} ${navData.isActive && styles['home--active']}`}>
        <div className={styles.home__body}>
          <div className={styles.home__icon}>
            <img src={homeImg} alt='home' />
          </div>
        </div>
      </NavLink>

      <NavLink
        to='/about'
        onClick={validateNavigation}
        className={navData => `${styles.footer__btn} ${navData.isActive && styles['footer__btn--active']}`}>
        <img src={infoImg} alt="about" />
      </NavLink>

      <div onClick={toggleFullScreen} className={styles.footer__full}>
        <img src={fullscreenImg} alt='fullscreen' />
      </div>
    </footer>
  )
}

export { Footer };