import React from 'react'
import { useTranslation } from 'react-i18next';
import { usePractice } from './usePractice';
import { Layout } from '../../layouts';
import { Game } from './Game/Game';
import styles from "./Practice.module.scss";

export const Practice = () => {
  const { t, i18n } = useTranslation()
  const { categories, selectedSound, setSelectedSound } = usePractice()

  return (
    <Layout>
      {selectedSound ?
        <Game sound={selectedSound} onClose={() => setSelectedSound(null)} />
        :
        <div className={styles.wrapper}>
          <h1 className={styles.wrapper__title}>{t('Practice Mode')}</h1>
          <div className={styles.wrapper__categories}>
            {categories.map(({ value, text, icon, color, items }) =>
              <div className={styles.category} style={{ '--color': color }} key={value}>
                <div className={styles.category__header}>
                  <span>{icon}</span>
                  <span className={styles.category__text}>{text[i18n.language]}</span>
                </div>
                <div className={styles.category__items}>
                  {items.map(({ value, text, icon }) =>
                    <div onClick={() => setSelectedSound(value)} className={styles.item} key={value}>
                      <span>{icon}</span>
                      <span className={styles.item__text}>{text[i18n.language]}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>}
    </Layout>
  )
}
