import React, { createContext, useContext, useEffect, useState } from 'react';
import { http } from '../services/http';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      loginSuccessfully(accessToken);
    } else {
      setAuthenticated(false);
    }
  }, [])

  useEffect(() => {
    if (!isAuthenticated) return

    http.get('auth/user')
      .then((userData) => setUser(userData))
      .catch((err) => {
        setAuthenticated(false);
        console.error(err);
      });
  }, [isAuthenticated])

  const loginSuccessfully = token => {
    setAuthenticated(true);
    http.setToken(token);
  }

  const login = async ({ username, password }) => {
    const { access_token: accessToken } = await http.post('auth/login', JSON.stringify({ username, password }));
    localStorage.setItem('access_token', accessToken);
    loginSuccessfully(accessToken);
  }

  const logout = () => {
    localStorage.removeItem('access_token');
    setAuthenticated(false);
    http.setToken('');
    setUser(null)
  }

  const getKey = () => user?.api_keys?.[0]?.key

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, getKey }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)