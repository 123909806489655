import React from 'react'
import { Header } from '../../components/Header'
import { ExpandableImage } from '../../components/ExpandableImage/ExpandableImage';

import styles from './Error.module.scss'
import img1 from './assets/1.jpg';
import img2 from './assets/2.jpg';
import img3 from './assets/3.jpg';
import img4 from './assets/4.jpg';

export const Error = () => {
    return (
        <>
            <Header />
            <div className={styles.wrapper}>
                <div className='app-container'>
                    <h2>Use your microphone to play</h2>
                    <p>You can use your microphone to play in Google Chrome and Mozilla Firefox</p>
                    <ol>
                        <li>On your device, open the Chrome or Firefox app</li>
                        <li>To the right of the address bar, tap <strong>More</strong>.</li>
                        <ExpandableImage src={img1} width={260} alt="img1" />
                        <li>Tap <strong>Settings</strong>.</li>
                        <ExpandableImage src={img2} width={260} alt="img2" />
                        <li>Tap <strong>Site Settings</strong>.</li>
                        <ExpandableImage src={img3} width={260} alt="img3" />
                        <li>Tap <strong>Microphone</strong>.</li>
                        <li>Tap to turn the microphone or camera on or off.
                            If you see the site you want to use under <strong>Blocked</strong>, tap the site and then <strong>Access your microphone</strong> and then <strong>Allow</strong>.</li>
                        <ExpandableImage src={img4} width={260} alt="img4" />
                    </ol>
                </div>
            </div>
        </>
    )
}
