import { useState, useEffect, useRef } from "react";
import * as MicRecorder from 'mic-recorder-to-mp3';

const MAX_SECONDS = 5
const BIT_RATE = 128

export const useRecorder = ({ nextStep }) => {
    const recorder = useRef(null);
    const [error, setError] = useState(false);
    const [time, setTime] = useState(MAX_SECONDS);
    const [recording, setRecording] = useState(false);
    const recordingInterval = useRef(null)

    const startTimer = () => {
        recordingInterval.current = setInterval(
            () => setTime(time => time === 0 ? MAX_SECONDS : time - 1),
            1000,
        )
    }

    useEffect(() => {
        if (time === 0) stopRecording();
    }, [time])


    const startRecording = async () => {
        if (recording) return
        try {
            await recorder.current.start()
            setRecording(true);
            startTimer();
        } catch (error) {
            setError(true)
        }
    }

    const stopRecording = async () => {
        setRecording(false);
        setTime(MAX_SECONDS);
        try {
            const [buffer, blob] = await recorder.current.stop().getMp3()
            const file = new File(buffer, 'me-at-thevoice.mp3', {
                type: blob.type,
                lastModified: Date.now()
            });
            const audioURL = window.URL.createObjectURL(file);
            nextStep({ audioBlob: file, audioURL });
        } catch (error) {
            setError(true)
        }
    }

    const initializeRecorder = async () => {
        setError(false)
        if (navigator.mediaDevices) {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true });
                recorder.current = new MicRecorder({
                    bitRate: BIT_RATE
                });
            } catch (error) {
                console.log(error);
                setError(true)
            }
        } else {
            console.log("Media Devices will work only with SSL...");
            setError(true)
        }
    }

    useEffect(() => {
        initializeRecorder();

        return () => { if (recordingInterval.current) clearInterval(recordingInterval.current) }
    }, [nextStep]);

    return { recording, time, error, initializeRecorder, startRecording, stopRecording }
}