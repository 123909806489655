import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useRecorder } from "./useRecorder";
import ScaleLoader from "react-spinners/ScaleLoader";
import Header from '../Header';
import { AspectRatio } from "../../AspectRatio/AspectRatio";
import { ScalableComponent } from '../../ScalableComponent/ScalableComponent'

import styles from './Recorder.module.scss';
import Microphone from '../../../images/microphone.svg';
import Stop from '../../../images/stop.svg';
import retryImg from '../assets/retry.png';

export const Recorder = ({ soundClass, soundCategory, nextStep, isPractice = false }) => {
    const { t, i18n } = useTranslation()
    const { recording, time, error, initializeRecorder, startRecording, stopRecording } = useRecorder({ nextStep })

    return (<>
        {!isPractice && <Header title={soundCategory.label[i18n.language]} color={soundCategory.color} />}
        <div className={styles.wrapper}>
            <ScalableComponent value={750}>
                <AspectRatio ratio={1.3} width={360} className={styles.wrapper__body}>
                    <div className={styles.record}>
                        <div className={styles.record__body}>
                            {recording && (
                                <span className={styles.record__title}>{time}</span>
                            )}
                            {t("Try to imitate the sound of a")} <br />
                            <div className={styles.record__class}>
                                <span>{soundClass.label[i18n.language]}</span>
                            </div>
                            {
                                error ?
                                    <>
                                        <div className={`app-card ${styles.record__error}`}>{t("Recorder Error")} <Link to='/error' target="_blank">{t("here")}</Link></div>
                                        <button className={`${styles.record__button} ${styles['record__button--active']}`} onClick={initializeRecorder}>
                                            <img src={retryImg} alt="Retry" />
                                        </button>
                                    </>
                                    :
                                    <>
                                        {recording ?
                                            <button className={`${styles.record__button} ${styles['record__button--active']}`} onClick={stopRecording}>
                                                <img src={Stop} alt="stop" />
                                            </button>
                                            :
                                            <button className={styles.record__button} onClick={startRecording}>
                                                <img src={Microphone} alt="mic" />
                                            </button>
                                        }</>
                            }

                            {recording && (
                                <div className={styles.record__animation}>
                                    <ScaleLoader color="rgb(226,92,173)" />
                                    <ScaleLoader color="rgb(226,92,173)" />
                                    <ScaleLoader color="rgb(226,92,173)" />
                                </div>
                            )}
                        </div>
                    </div>
                </AspectRatio>
            </ScalableComponent>
        </div>
    </>);
}