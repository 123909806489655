import React, { createContext, useContext, useState } from 'react';

const GameContext = createContext();

export const GameProvider = ({ children }) => {
    const [gameStep, setGameStep] = useState(4)

    return (
        <GameContext.Provider value={{ gameStep, setGameStep }}>
            {children}
        </GameContext.Provider>
    )
}

export const useGameContext = () => useContext(GameContext)