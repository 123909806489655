import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { usePlayback } from "../../hooks/usePlayback";
import { useAuthContext } from '../../context/AuthContext';
import { soundClasses } from '../../categories';
import { Ranking } from './Ranking/Ranking';
import { ShareRoundButton } from '../../components/ShareRoundButton/ShareRoundButton'

import styles from "./Leaderboard.module.scss";
import Play from '../../images/play.png';
import Stop from '../../images/stop.svg';
import shareImg from './assets/share_icon.png';

export const SoundLeaderboard = ({ ranking, selectedSound, setSelectedSound, loading }) => {
  const { t, i18n } = useTranslation()
  const { isPlaying, playByUrl, stop } = usePlayback()
  const { user } = useAuthContext()
  const [audioId, setAudioId] = useState('')
  const orderedSounds = soundClasses.sort((a, b) => a.label[i18n.language].localeCompare(b.label[i18n.language]))

  useEffect(() => {
    stop();
  }, [selectedSound])

  const handlePlayback = ({ id, url }) => {
    setAudioId(id)
    playByUrl({ url, isBase64: true })
  }

  return (
    <Ranking ranking={ranking} loading={loading}>
      {{
        filters:
          <div className={styles.filter}>
            <span>{t("Sound")}:</span>
            <select value={selectedSound} onChange={e => setSelectedSound(e.target.value)} className={styles.filter__select}>
              {orderedSounds.map(({ class: id, label }) => <option value={id} key={id}>{label[i18n.language]}</option>)}
            </select>
          </div>,
        columns: [
          item => audioId === item.id && isPlaying ?
            <div onClick={stop} className={`${styles.item__btn} ${styles['item__btn--stop']}`}>
              <img src={Stop} alt="stop" />
            </div>
            :
            <div onClick={() => handlePlayback({ id: item.id, url: item.audio })} className={`${styles.item__btn} ${styles['item__btn--play']}`}>
              <img src={Play} alt="play" />
            </div>,
          item => item.id_user === user?.id &&
            <ShareRoundButton sound={soundClasses.find(item => item.class === selectedSound)?.label[i18n.language]} idRound={item.id} position='left'>
              <div className={`${styles.item__btn} ${styles['item__btn--share']}`}>
                <img src={shareImg} alt='share' />
              </div>
            </ShareRoundButton>
        ]
      }}
    </Ranking>
  )
}
