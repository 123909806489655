import { useEffect, useState } from 'react'
import { soundCategories, soundClasses } from '../../categories';

export const usePractice = () => {
  const [categories, setCategories] = useState([])
  const [selectedSound, setSelectedSound] = useState(null)

  useEffect(() => {
    const categories = soundCategories.map(({ category, label, emoji, color, classes }) => {
      const items = soundClasses
        .filter(({ class: value }) => classes.includes(value))
        .map(({ class: value, label, emoji }) => ({ value, text: label, icon: emoji }))
      return { value: category, text: label, icon: emoji, color, items }
    })
    setCategories(categories)
  }, [])

  return { categories, selectedSound, setSelectedSound }
}
