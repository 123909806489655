import React from 'react'
import { useTranslation } from 'react-i18next';
import { useLeaderboard } from './useLeaderboard'
import { GeneralLeaderboard } from './GeneralLeaderboard'
import { SoundLeaderboard } from './SoundLeaderboard'
import styles from "./Leaderboard.module.scss";
import { Layout } from '../../layouts';

export const Leaderboard = () => {
  const { t } = useTranslation()
  const { leaderboardToShow, setLeaderboardToShow, generalRanking, soundRanking, selectedSound, setSelectedSound, loadingGeneralRanking, loadingSoundRanking } = useLeaderboard()

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 className={styles.wrapper__title}>{t("Leaderboard")}</h1>
        <div className={styles.tabs}>
          <div
            onClick={() => setLeaderboardToShow('general')}
            className={`${styles.tabs__btn} ${leaderboardToShow === 'general' && styles['tabs__btn--active']}`}
          >General</div>
          <div
            onClick={() => setLeaderboardToShow('sound')}
            className={`${styles.tabs__btn} ${leaderboardToShow === 'sound' && styles['tabs__btn--active']}`}
          >{t("Sound")}</div>
        </div>
        {leaderboardToShow === 'general' && <GeneralLeaderboard ranking={generalRanking} loading={loadingGeneralRanking} />}
        {leaderboardToShow === 'sound' && <SoundLeaderboard ranking={soundRanking} selectedSound={selectedSound} setSelectedSound={setSelectedSound} loading={loadingSoundRanking} />}
      </div>
    </Layout>
  )
}
