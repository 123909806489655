import { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { useDataContext } from '../../context/DataContext';
import { useGameContext } from '../../context/GameContext';

export const useFooter = () => {
    const location = useLocation()
    const { state, setState } = useDataContext()
    const { gameStep } = useGameContext()

    useEffect(() => {
        const onUnload = e => {
            if (gameIsRunning()) {
                e.preventDefault();
                e.returnValue = '';
            }
        }
        window.addEventListener('beforeunload', onUnload)
        return () => window.removeEventListener('beforeunload', onUnload)
    }, [state])

    const validateNavigation = e => {
        if (gameIsRunning()) {
            const exit = window.confirm("Are you sure you want to leave the current game?")
            if (exit) setState('lobby')
            else e.preventDefault()
        }
    }

    const gameIsRunning = () => location.pathname === '/' && state === 'game' && gameStep !== 5

    return { validateNavigation }
}
