import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Wheel } from 'react-custom-roulette';
import { soundCategories, soundClasses } from '../../../categories';
import { useAuthContext } from '../../../context/AuthContext';
import styles from './SpinWheel.module.scss'

function SpinWheel({ round, maxRounds, coins, soundsUsed, nextStep }) {
  const { t, i18n } = useTranslation()
  const { user } = useAuthContext()
  const [data, setData] = useState(soundCategories);
  const [spin, setSpin] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(null);

  const wheelData = data.map(cat => ({ option: cat.emoji }));;
  const colors = data.map(cat => cat.color);
  const category = !spin && categoryIndex !== null && data[categoryIndex];
  function handleSpinClick() {
    setCategoryIndex(Math.floor(Math.random() * wheelData.length));
    setSpin(true);
  }

  function onStopSpinning() {
    const categoryClasses = data[categoryIndex].classes;
    const classIndex = Math.floor(Math.random() * categoryClasses.length);
    const soundClass = soundClasses.find(c => c.class === categoryClasses[classIndex])
    setSpin(false);
    setTimeout(() => {
      nextStep({ soundClass, soundCategory: data[categoryIndex] });
    }, 3000);
  }

  useEffect(() => {
    setTimeout(() => {
      const img = document.querySelector(`#wheel img[alt=roulette-static]`);
      if (img) img.style.display = 'none';
    }, 0);
  }, []);

  useEffect(() => {
    const filteredClasses = soundCategories.map(category => {
      const classes = category.classes.filter(c => !soundsUsed.includes(c))
      return { ...category, classes }
    })
    const filteredCategories = filteredClasses.filter(category => category.classes.length > 0)

    setData(filteredCategories)
  }, [soundsUsed])

  return (
    <>
      <div className={styles.turns}>
        <div className={styles.turns__header}>
          <div className={`${styles.turns__chip} ${styles.turns__round}`}>
            <span>{`${round} - ${maxRounds}`}</span>
          </div>
          <div className={`${styles.turns__chip} ${styles.turns__coins}`}>
            <span>{coins}</span>
          </div>
        </div>
        <div className={styles.turns__name}>
          {user?.user_name}
        </div>
      </div>
      <div className={styles['spin-wheel']}>
        {!!category && (
          <div className={styles['spin-wheel__selected-category']}>
            <span style={{ color: category.color }}>
              {category.label[i18n.language]}
            </span>
          </div>
        )}
        <div id='wheel' className={styles['spin-wheel__wrapper']}>
          <Wheel
            mustStartSpinning={spin}
            prizeNumber={categoryIndex}
            data={wheelData}
            onStopSpinning={onStopSpinning}
            fontSize={60}
            textDistance={65}
            perpendicularText
            backgroundColors={colors}
            outerBorderColor="rgb(98,98,98)"
            outerBorderWidth="10"
            innerRadius="20"
            innerBorderWidth="2"
            innerBorderColor="rgb(98,98,98)"
            radiusLineWidth="2"
            radiusLineColor="rgb(98,98,98)"
          />
          <button className={styles['spin-wheel__button']} onClick={handleSpinClick}>
            <span>{t("Spin")}</span>
            <div className={styles['spin-wheel__arrow']}></div>
          </button>
        </div>
      </div>
    </>
  );
}

export { SpinWheel };
