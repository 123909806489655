export const soundCategories = [
  {
    category: 'animals',
    label: {
      en: 'Animals',
      es: 'Animales',
    },
    emoji: '🦋',
    color: 'rgb(226,92,173)',
    classes: ['cat', 'dog', 'cow', 'hen', 'rooster'],
  },
  {
    category: 'human',
    label: {
      en: 'Human',
      es: 'Humano',
    },
    emoji: '👩🏽',
    color: 'rgb(224,65,64)',
    classes: ['snoring', 'sneezing', 'crying'],
  },
  {
    category: 'domestic',
    label: {
      en: 'Domestic',
      es: 'Doméstico',
    },
    emoji: '🏘️',
    color: 'rgb(132,77,178)',
    classes: ['ticking', 'clicking'],
  },
  {
    category: 'urban',
    label: {
      en: 'Urban',
      es: 'Urbano',
    },
    emoji: '🗽',
    color: 'rgb(241,220,95)',
    classes: ['helicopter', 'chainsaw', 'siren'],
  },
  {
    category: 'nature',
    label: {
      en: 'Nature',
      es: 'Naturaleza',
    },
    emoji: '🌵',
    color: 'rgb(61,122,196)',
    classes: ['wind', 'birds', 'water'],
  },
]

export const soundClasses = [
  {
    class: 'cat',
    label: {
      en: 'Cat meowing',
      es: 'Gato maullando',
    },
    emoji: '😺',
  },
  {
    class: 'chainsaw',
    label: {
      en: 'Chainsaw',
      es: 'Motosierra',
    },
    emoji: '⚒️',
  },
  {
    class: 'snoring',
    label: {
      en: 'Person snoring',
      es: 'Persona roncando',
    },
    emoji: '😴',
  },
  {
    class: 'cow',
    label: {
      en: 'Cow',
      es: 'Vaca',
    },
    emoji: '🐮',
  },
  {
    class: 'crying',
    label: {
      en: 'Baby Crying',
      es: 'Bebé llorando',
    },
    emoji: '🍼',
  },
  {
    class: 'dog',
    label: {
      en: 'Dog',
      es: 'Perro ladrando',
    },
    emoji: '🐶',
  },
  {
    class: 'helicopter',
    label: {
      en: 'Helicopter',
      es: 'Helicoptero',
    },
    emoji: '🚁',
  },
  {
    class: 'hen',
    label: {
      en: 'Hen',
      es: 'Gallina',
    },
    emoji: '🥚',
  },
  {
    class: 'siren',
    label: {
      en: 'Siren',
      es: 'Sirena',
    },
    emoji: '🚨',
  },
  {
    class: 'sneezing',
    label: {
      en: 'Person Sneezing',
      es: 'Persona estornudando',
    },
    emoji: '🤧',
  },
  {
    class: 'ticking',
    label: {
      en: 'Clock ticking',
      es: 'Reloj haciendo tic-tac',
    },
    emoji: '⏱️',
  },
  {
    class: 'rooster',
    label: {
      en: 'Rooster',
      es: 'Gallo',
    },
    emoji: '🐓',
  },
  {
    class: 'clicking',
    label: {
      en: 'Mouse clicking',
      es: 'Mouse haciendo clic',
    },
    emoji: '🖱️',
  },
  {
    class: 'wind',
    label: {
      en: 'Wind blowing',
      es: 'Viento soplando',
    },
    emoji: '🍃',
  },
  {
    class: 'birds',
    label: {
      en: 'Bird chirping',
      es: 'Pájaros cantando',
    },
    emoji: '🐦',
  },
  {
    class: 'water',
    label: {
      en: 'Water droplet',
      es: 'Gota de agua',
    },
    emoji: '💧',
  },
];