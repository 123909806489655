import React, { useState, useRef } from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon
} from "react-share";
import { useClickOutside } from "../../hooks/useClickOutside";

import styles from './ShareButton.module.scss'
import shareImg from "./assets/share.png";
import checkImg from "./assets/check.png";

export const ShareButton = ({ text, path, position, children }) => {
  const menuRef = useRef(null)
  const [show, setShow] = useState(false)
  const [copied, setCopied] = useState(false)
  const url = `${window.location.origin}/${path}`

  useClickOutside(menuRef, () => {
    setShow(false)
    setCopied(false)
  })

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
    setCopied(true)
  }

  return (
    <div ref={menuRef} className={`${styles.wrapper} ${styles[`wrapper--${position}`]}`}>
      {show &&
        <div className={styles.wrapper__popup}>
          {copied
            ? <div className={styles.wrapper__btn} style={{ pointerEvents: 'none', opacity: 0.4 }}>
              <img src={checkImg} alt='check' />
            </div>
            : <div onClick={copyToClipboard} className={styles.wrapper__btn}>
              <img src={shareImg} alt='share' />
            </div>
          }


          <FacebookShareButton
            url={url}
            quote={text}
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>

          <TwitterShareButton
            url={url}
            title={text}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={url}
            title={text}
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>

          <TelegramShareButton
            url={url}
            title={text}
          >
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>

          <EmailShareButton
            url={url}
            subject={text}
            body={text}
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>}

      <div style={{ width: 'max-content' }} onClick={() => { setShow(!show); setCopied(false) }}>
        {children}
      </div>
    </div>
  )
}
