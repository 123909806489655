import { useEffect, useRef, useState } from 'react'
import { getEnv } from '../../../services/env';
import { soundClasses, soundCategories } from '../../../categories';

export const useGame = ({ sound }) => {
  const audio = useRef(null)
  const [isPlaying, setPlaying] = useState(false)
  const soundClass = soundClasses.find(({ class: value }) => value === sound)
  const soundCategory = soundCategories.find(({ classes }) => classes.includes(sound))

  useEffect(() => {
    return () => {
      handleStop();
    }
  }, [])

  const handlePlay = () => {
    handleStop()
    audio.current = new Audio(`${getEnv('REACT_APP_API_URL')}/sound/${sound}?${Date.now()}`);
    audio.current.onended = clearAudio
    audio.current.onerror = clearAudio
    audio.current.play();
    setPlaying(true)
  }

  const handleStop = () => {
    if (audio.current) {
      audio.current.pause();
      audio.current.currentTime = 0;
    }
    clearAudio()
  }

  const clearAudio = () => {
    audio.current = null
    setPlaying(false)
  }

  return { isPlaying, handlePlay, handleStop, soundClass, soundCategory }
}
