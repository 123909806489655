import React from 'react'
import { useTranslation } from 'react-i18next';
import { ShareButton } from '../ShareButton/ShareButton'

export const ShareRoundButton = ({ sound, idRound, position, children }) => {
    const { t } = useTranslation()

    return (
        <ShareButton text={t("Share Text").replace('$1', sound)} path={`game/round/${idRound}`} position={position}>
            {children}
        </ShareButton>
    )
}
