import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { http } from '../../services/http';
import { Layout } from '../../layouts';
import { Button } from '../../components/Button';
import styles from "./Auth.module.scss";

export const Register = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({ email: '', user_name: '', password: '', confirmPassword: '' })
  const [error, setError] = useState('')

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(t('You must specify an email'))
      .email(t('This is not a valid email')),
    user_name: Yup.string()
      .required(t('You must specify a nick')),
    password: Yup.string()
      .required(t('You must specify a password'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('Password must contain')
      ),
    confirmPassword: Yup.string()
      .required(t('You must confirm above password'))
      .oneOf([Yup.ref('password')], t('Password must match'))
  });

  const handleSubmit = async e => {
    e.preventDefault()
    if (loading) return
    setError('');
    setLoading(true)

    const { email, user_name, password, confirmPassword } = user
    try {
      await schema.validate({ email, user_name, password, confirmPassword })
    } catch (error) {
      setError(error.errors.join('\n'));
      setLoading(false)
      return
    }

    try {
      await http.post('auth/signup', JSON.stringify({ email, user_name, password }))
      login({ username: email, password })
      navigate('/')
    } catch (error) {
      setError(error.message);
    }

    setLoading(false)
  }

  const handleChange = e => {
    const { value, name } = e.target
    setUser({ ...user, [name]: value })
  }

  const handleFocusOut = () => {
    setUser(prev => ({
      ...prev,
      user_name: prev.email.split('@')[0]
    }))
  }

  return isAuthenticated ?
    <Navigate to="/" />
    :
    <Layout>
      <div className={styles.wrapper}>
        <div className={`app-card ${styles.form}`}>
          <h2>{t("Register")}</h2>
          <form onSubmit={handleSubmit}>
            <input type='email' onChange={handleChange} onBlur={handleFocusOut} value={user.email} name='email' placeholder={t("Email")} required />
            <input type='text' onChange={handleChange} value={user.user_name} name='user_name' placeholder={t("Nick")} required />
            <input type='password' onChange={handleChange} value={user.password} name='password' placeholder={t("Password")} required />
            <input type='password' onChange={handleChange} value={user.confirmPassword} name='confirmPassword' placeholder={t("Confirm Password")} required />
            {error && <span className={styles.form__error}>{`⚠ ${error}`}</span>}
            <span dangerouslySetInnerHTML={{ __html: t("Register Info") }} className={styles.form__text}></span>
            <Button type='submit' disabled={loading} small>{t("Create Account")}</Button>
          </form>
          <span className={styles.form__info}>{t("Already have an account?")} <Link to='/login'>{t("Login")}</Link></span>
        </div>
      </div>
    </Layout>
}
