import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { http } from '../../services/http';
import { usePlayback } from "../../hooks/usePlayback";

import { soundCategories, soundClasses } from '../../categories';

export const useRound = () => {
  const { isPlaying, playByUrl, stop } = usePlayback()
  const { idRound } = useParams();
  const [round, setRound] = useState(null)
  const [category, setCategory] = useState(null)
  const [sound, setSound] = useState(null)

  useEffect(() => {
    http.get(`game/round/${idRound}`).then(round => {
      setRound(round);
      const { sound_name: soundName } = round
      setCategory(soundCategories.find(({ classes }) => classes.includes(soundName)))
      setSound(soundClasses.find(({ class: soundClass }) => soundClass === soundName))
    }).catch(err => console.log(err))
  }, [])

  const handlePlayback = () => playByUrl({ url: round.audio, isBase64: true })

  const handleStop = () => stop()

  const formatDate = dateString => new Date(dateString).toLocaleDateString("es-ES")

  return { round, category, sound, isPlaying, handleStop, handlePlayback, formatDate }
}
