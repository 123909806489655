export default {
  "Sign Out": "Sign Out",
  "Sign In": "Sign In",
  "Login": "Login",
  "Sign up for free": "Sign up for free",
  "New to the game": "New to the game",
  "Email": "Email",
  "Password": "Password",
  "Register": "Register",
  "Confirm Password": "Confirm Password",
  "Create Account": "Create Account",
  "Register Info": `By proceeding, I agree to Cogniflow <a href='https://www.cogniflow.ai/terms' target='_blank'>Terms of Services</a> and acknowledge the <a href='https://www.cogniflow.ai/privacy-policy' target='_blank'>Privacy Policy</a>.`,
  "Already have an account?": "Already have an account?",

  "Practice Mode": "Practice Mode",
  "New Game": "New Game",
  "Loading": "Loading",

  "Leaderboard": "Leaderboard",
  "Sound": "Sound",
  "You": "You",
  "Unknown": "Unknown",
  "No records yet": "No records yet",
  "Be the first to compete": "Be the first to compete",

  "Spin": "Spin",
  "Try to imitate the sound of a": "Try to imitate the sound of a",
  "Great": "Great",
  "Oh no": "Oh no",
  "It sounds like a": "It sounds like a",
  "Continue": "Continue",
  "Retry": "Retry",
  "Share": "Share",
  "Bad": "Bad",
  "Excellent": "Excellent",
  "Game Over": "Game Over",
  "Play Again": "Play Again",
  "Recorder Error": "Recording not working? Please check",
  "here": "here",

  "Play a random sound of": "Play a random sound of",

  "Share Text": "Listen to me imitating a $1 while I play Cheat The AI! (powered by Cogniflow AI). Sign up for free and I challenge you to beat my score!",
  "Round Shared Text": "This is me imitating a $1 while I play",
  "Play": "Play",

  "About": "About",
  "About Content": `
    <p>The CHEAT THE AI! game (previously, cheat me now!) was proposed by <strong>Cogniflow</strong> as part of a hackaton (PandemIA edition, March 2021) where Cogniflow was put to the test by many developers without any knowledge in the AI field during two days.</p>

    <p>In this hackaton, <strong><a href='https://www.linkedin.com/in/andrearosr/' target='_blank'>Andrea Rosales</a></strong> (developer) and <strong><a href='https://www.linkedin.com/in/maria-camila-suarez-824977149/' target='_blank'>María Camila Suarez</a></strong> (designer) partnered up to implement the first version of the proposed game with the help from Cogniflow's Artificial Intelligence backend.</p>

    <p>You can read the full article about the the hackaton <strong><a href='https://uruit.com/blog/artificial-intelligence-hackathon/' target='_blank'>here</a></strong>.</p>

    <p>The AI model for the game has been trained using a subset of the ESC-50 dataset and it includes the following categories and sounds:</p>

    <ul>
      <li><strong>Animals:</strong> cats, dogs, cows, chickens and roosters.</li>
      <li><strong>Humans:</strong> sneezing, snoring and crying babies.</li>
      <li><strong>Domestic:</strong> the click of a mouse and the second hand of a clock.</li>
      <li><strong>Urban:</strong> helicopter, chainsaw and siren.</li>
      <li><strong>Nature:</strong> wind, drops of water and birds singing.</li>
    </ul>

    <p>A refined second version of the game adding new cool features like practice and competition mode, global and per sound leaderboards, social media sharing (among others) was extended by the Congliflow's team to showcase the power of AI backends supporting smart applications in any industry and domain (in this case games & entertainment).</p>

    <p><strong>The Cogniflow's team.</strong></p>`,

  "You must specify an email": "You must specify an email",
  "This is not a valid email": "This is not a valid email",
  "You must specify a nick": "You must specify a nick",
  "You must specify a password": "You must specify a password",
  "Password must contain": "Password must contain at least 8 characters including upper/lowercase letters and numbers",
  "You must confirm above password": "You must confirm above password",
  "Password must match": "Password must match",
}