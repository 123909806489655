import React from 'react';
import { Game as GameComponent } from "../../components/Game";

function Game() {
  return (
    <div className='game-screen'>
      <GameComponent />
    </div>
  );
}

export { Game };
