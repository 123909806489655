import React from 'react'
import styles from './AspectRatio.module.scss';

export const AspectRatio = ({ width = 340, ratio = 1, className, children }) => {
    return (
        <div className={`${styles.wrapper} ${className}`} style={{ '--width': `${width}px`, '--ratio': `${ratio * 100}%` }}>
            <div className={styles.wrapper__1}>
                <div className={styles.wrapper__2}>
                    {children}
                </div>
            </div>
        </div>
    )
}
