import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { Layout } from '../../layouts';
import { Button } from '../../components/Button';
import styles from "./Auth.module.scss";

export const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({ username: '', password: '' })
  const [error, setError] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    setError('');
    setLoading(true)
    const { username, password } = user
    try {
      await login({ username, password })
      navigate('/')
    } catch (error) {
      setError(error.message);
    }
    setLoading(false)
  }

  const handleChange = e => {
    const { value, name } = e.target
    setUser({ ...user, [name]: value })
  }

  return isAuthenticated ?
    <Navigate to="/" />
    :
    <Layout>
      <div className={styles.wrapper}>
        <div className={`app-card ${styles.form}`}>
          <h2>{t("Sign In")}</h2>
          <form onSubmit={handleSubmit}>
            <input type='email' onChange={handleChange} value={user.username} name='username' placeholder={t("Email")} required />
            <input type='password' onChange={handleChange} value={user.password} name='password' placeholder={t("Password")} required />
            {error && <span className={styles.form__error}>{`⚠ ${error}`}</span>}
            <Button type="submit" disabled={loading} small>{t("Login")}</Button>
          </form>
          <span className={styles.form__info}>{t("Nuevo en el juego")}? <Link to='/register'>{t("Sign up for free")}</Link></span>
        </div>
      </div>
    </Layout>
}
