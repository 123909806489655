import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { getEnv } from "../../services/env";
import { useDataContext } from "../../context/DataContext";
import { useAuthContext } from "../../context/AuthContext";
import { FetchError } from '../../utils/errors';

import Header from './Header';
import { ScalableComponent } from '../../components/ScalableComponent/ScalableComponent'
import FadeLoader from "react-spinners/FadeLoader";

function Loading({ soundCategory, audioBlob, soundClass, attempt, nextStep, isPractice = false }) {
  const { t, i18n } = useTranslation()
  const { idGame, setState, setTooManyRequests } = useDataContext()
  const { getKey } = useAuthContext()

  useEffect(() => {
    const predict = () => {
      var reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = async () => {
        const base64 = reader.result.split(',')[1];
        await fetchPrediction({ base64 })
      }
    }
    predict();
  }, []);

  const fetchPrediction = async ({ base64 }) => {
    const formData = new FormData()
    formData.append('sound_name', soundClass.class)
    formData.append('attempt', isPractice ? 1 : attempt)
    formData.append('format', 'ogg')
    formData.append('base64_audio', base64)
    if (!isPractice) {
      formData.append('id_game', idGame)
      formData.append('audio', audioBlob)
      formData.append('api_key', getKey())
    }
    const url = `${getEnv('REACT_APP_API_URL')}/${isPractice ? 'predict/practice' : 'predict'}`

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (response.ok) nextStep({ prediction: result });
      else throw new FetchError(result.detail, response.status);
    } catch (error) {
      setState('lobby')
      if (error.statusCode === 401)
        setTooManyRequests(true)
    }
  }

  return (
    <>
      {!isPractice && <Header title={soundCategory.label[i18n.language]} color={soundCategory.color} />}
      <div className="wrapper">
        <ScalableComponent value={800}>
          <div className="record">
            <span className="record__loading">
              {t("Loading")}...
            </span>
            <FadeLoader
              color="rgb(98,98,98)"
              height={30}
              width={15}
              radius={10}
              margin={25}
              css="margin-left: -23px;"
            />
          </div>
        </ScalableComponent>
      </div>
    </>
  );
}

export { Loading };