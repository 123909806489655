import React from 'react'
import { useTranslation } from 'react-i18next';
import { useGame } from './useGame';
import { Game as GameComponent } from '../../../components/Game';
import { Button } from "../../../components/Button";

import styles from './Game.module.scss'
import backImg from './assets/back.png';
import Play from '../../../images/play.png';
import Stop from '../../../images/stop.svg';

export const Game = ({ sound, onClose }) => {
  const { t, i18n } = useTranslation()
  const { isPlaying, handlePlay, handleStop, soundClass, soundCategory } = useGame({ sound })

  return (
    <div className='app-container'>
      <div className={styles.header}>
        <Button onClick={onClose} className={styles.header__back} small icon>
          <img src={backImg} alt='back' />
        </Button>
        <div className={`app-card ${styles.player}`}>
          <div className={styles.player__emoji}>{soundClass.emoji}</div>
          <div className={styles.player__info}>
            <div className={styles['player__info-text']}>{t("Play a random sound of")}</div>
            <div className={styles['player__info-sound']}>{soundClass.label[i18n.language]}</div>
          </div>
          {isPlaying ?
            <div onClick={handleStop} className={`${styles.player__btn} ${styles['player__btn--stop']}`}>
              <img src={Stop} alt="stop" />
            </div>
            :
            <div onClick={handlePlay} className={`${styles.player__btn} ${styles['player__btn--play']}`}>
              <img src={Play} alt="play" />
            </div>}
        </div>
      </div>

      {soundClass && soundCategory && <GameComponent soundClass={soundClass} soundCategory={soundCategory} isPractice />}
    </div>
  )
}
