import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLang } from '../../hooks/useLang';
import { useHeader } from './useHeader';

import styles from './Header.module.scss'
import cogniflowImg from "./assets/cogniflow.svg";
import userImg from "./assets/user.png";
import signOutImg from "./assets/sign-out.png";
import enImg from "./assets/en.jpg";
import esImg from "./assets/es.webp";

const languages = [
  { text: 'EN', value: 'en', img: enImg },
  { text: 'ES', value: 'es', img: esImg }
]

export const Header = () => {
  const { t, i18n } = useTranslation()
  const { changeLanguage } = useLang()
  const avatarRef = useRef(null)
  const { isAuthenticated, user, showMenu, handleAvatarClick, handleLogout } = useHeader({ avatarRef })

  return (
    <div className={styles.wrapper}>
      <a href='https://www.cogniflow.ai/' target='_blank' className={styles.wrapper__logo}>
        <span className={styles['wrapper__logo-text']}>Powered by</span>
        <img src={cogniflowImg} alt='cogniflow' />
      </a>
      <div className={`app-container ${styles.wrapper__header}`}>
        <div className={styles.lang}>
          {languages.map(({ text, value, img }) =>
            <div onClick={() => changeLanguage(value)} className={`${styles.lang__item} ${i18n.language === value && styles['lang__item--active']}`} key={value}>
              <img src={img} alt={text} />
              <span>{text}</span>
            </div>)
          }
        </div>

        {isAuthenticated ?
          <div ref={avatarRef} className={styles.avatar}>
            <div onClick={handleAvatarClick} className={styles.avatar__user}>
              <img src={userImg} alt='user' />
            </div>

            {showMenu &&
              <div className={`app-card ${styles.avatar__menu}`}>
                <div className={styles.avatar__name}>{user?.user_name}</div>
                <div className={styles.avatar__email}>{user?.email}</div>
                <div onClick={handleLogout} className={styles.avatar__btn}>
                  <img src={signOutImg} alt='signOut' />
                  <span>{t("Sign Out")}</span>
                </div>
              </div>}
          </div>
          :
          <Link to="/login" className={styles.wrapper__signin}>
            {t("Login")}
          </Link>
        }
      </div>
    </div>
  )
}
