import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../../context/DataContext';
import { useAuthContext } from '../../../context/AuthContext';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import styles from "./Ranking.module.scss";

import goldMedal from "../assets/gold-medal.png";
import silverMedal from "../assets/silver-medal.png";
import bronzeMedal from "../assets/bronze-medal.png";

export const Ranking = ({ ranking, loading, children }) => {
  const { t } = useTranslation()
  const { setState } = useDataContext()
  const { user } = useAuthContext()
  const formatDate = dateString => new Date(dateString).toLocaleDateString("es-ES")

  return (
    <>
      <div className={`app-card ${styles.ranking}`}>
        {children?.filters}

        {loading && <div className={styles.ranking__loading}>{t("Loading")}...</div>}

        {ranking.map((item, idx) =>
          <div className={`${styles.item} ${item.id_user === user?.id && styles['item--me']}`} key={idx} style={{ position: 'relative' }}>
            <div className={styles.item__position}>
              {
                idx === 0 ?
                  <img src={goldMedal} />
                  : idx === 1 ?
                    <img src={silverMedal} />
                    : idx === 2 ?
                      <img src={bronzeMedal} />
                      :
                      <span>{idx + 1}</span>
              }
            </div>
            <div className={styles.item__info}>
              <Tooltip text={item.user_name || t("Unknown")}>
                <span className={styles.item__name}>{item.id_user === user?.id ? `(${t("You")}) ${item.user_name}` : (item.user_name || t("Unknown"))}</span>
              </Tooltip>
              <span className={styles.item__date}>{formatDate(item.created_at)}</span>
            </div>
            <div>
              <span className={styles.item__score}>{item.score}</span>
              <span className={styles.item__units}>pts</span>
            </div>

            {children?.columns.map((column, idx) => <div key={idx}>{column(item)}</div>)}
          </div>
        )}

      </div>

      {ranking.length === 0 &&
        <div className={styles.empty}>
          <p>{t("No records yet")}.</p>
          <Link to="/" onClick={() => setState('lobby')} className={styles.empty__btn}>{t("Be the first to compete")}!</Link>
        </div>}
    </>
  )
}
