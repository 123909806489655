import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [state, setState] = useState('lobby')
  const [idGame, setIdGame] = useState('')
  const [tooManyRequests, setTooManyRequests] = useState(false)

  const reset = () => {
    setTooManyRequests(false)
  }

  return (
    <DataContext.Provider value={{ state, setState, idGame, setIdGame, tooManyRequests, setTooManyRequests, reset }}>
      {children}
    </DataContext.Provider>
  )
}

export const useDataContext = () => useContext(DataContext)