import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { http } from '../../services/http';
import { useAuthContext } from '../../context/AuthContext';
import { useDataContext } from '../../context/DataContext';

export const useLobby = ({ startGame }) => {
  const navigate = useNavigate();
  const { setIdGame } = useDataContext();
  const { isAuthenticated } = useAuthContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIdGame('');
  }, []);

  const handleNewGame = async () => {
    if (loading) return;
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    setLoading(true);
    try {
      const { id: idGame } = await http.post('game', JSON.stringify({}));
      startGame({ idGame });
    } catch (error) {
      if (error.statusCode === 401) navigate('/login');
      setLoading(false);
    }
  };

  return { loading, handleNewGame };
};
