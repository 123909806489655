import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useDataContext } from './context/DataContext';
import { useLang } from './hooks/useLang';

import { Layout } from './layouts';
import { Login } from './pages/Auth/Login';
import { Register } from './pages/Auth/Register';
import { About } from './pages/About';
import { Round } from './pages/Round';
import { Lobby } from './pages/Lobby';
import { Game } from './pages/Game';
import { Leaderboard } from './pages/Leaderboard';
import { Practice } from './pages/Practice';
import { Error } from './pages/Error';

import './App.scss';

function App() {
  const { refreshLanguage } = useLang()
  const { state, setState, setIdGame } = useDataContext()

  useEffect(() => {
    refreshLanguage()
  }, [])


  const handleStartGame = ({ idGame }) => {
    setIdGame(idGame)
    setState('game');
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <Layout>
            {state === 'lobby' && <Lobby startGame={handleStartGame} />}
            {state === 'game' && <Game />}
          </Layout>}
        />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="leaderboard" element={<Leaderboard />} />
        <Route path="practice" element={<Practice />} />
        <Route path="about" element={<About />} />
        <Route path="game/round/:idRound" element={<Round />} />
        <Route path="error" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
