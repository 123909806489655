import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useRound } from "./useRound";
import { Header } from "../../components/Header";
import styles from "./Round.module.scss";

import Play from '../../images/play.png';
import Stop from '../../images/stop.svg';

export const Round = () => {
  const { t, i18n } = useTranslation()
  const { round, sound, isPlaying, handleStop, handlePlayback, formatDate } = useRound()

  return (
    <div className={styles.wrapper}>
      <Header />
      {round &&
        <div className={styles.round}>

          <div className={styles.player}>
            {sound && <div className={styles.player__sound}>
              <span className={styles.player__icon}>{sound.emoji}</span>
              <span className={styles.player__text}>
                {`${t("Round Shared Text").replace('$1', sound.label[i18n.language]?.toLowerCase())} `}
                <Link to="/">Cheat The AI!</Link>
              </span>
            </div>}

            {isPlaying ?
              <div onClick={handleStop} className={`${styles.player__btn} ${styles['player__btn--stop']}`}>
                <img src={Stop} alt="stop" />
              </div>
              :
              <div onClick={handlePlayback} className={`${styles.player__btn} ${styles['player__btn--play']}`}>
                <img src={Play} alt="play" />
              </div>
            }
          </div>

          <div className={styles.info}>
            <div className={styles.info__name}>{round.user_name}</div>
            <div className={styles.info__date}>{formatDate(round.created_at)}</div>
            <div className={styles.info__score}>{`${round.score} pts`}</div>
          </div>

          <Link to="/" className={styles.info__btn}>{t("Play")}</Link>
        </div>
      }
    </div>
  )
}
