import { getEnv } from './env';
import { FetchError } from '../utils/errors';

class Http {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error('No api url specified for build');
    }
    this.baseUrl = baseUrl;
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  fetch(method) {
    return (url, body, headers) => {
      return fetch(`${this.baseUrl}/${url}`, {
        method,
        headers: {
          ...this.headers,
          ...(headers ?? {}),
        },
        body,
      }).then((response) =>
        response.json().then((result) => {
          if (response.ok) {
            return result;
          }

          throw new FetchError(result.detail, response.status);
        })
      );
    };
  }

  setToken(token) {
    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  post = this.fetch('POST');

  get = this.fetch('GET');

  put = this.fetch('PUT');

  delete = this.fetch('DELETE');

  patch = this.fetch('PATCH');
}

export const http = new Http(getEnv('REACT_APP_API_URL'));