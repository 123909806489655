import React, { useState } from 'react'
import styles from './ExpandableImage.module.scss'

export const ExpandableImage = ({ width, src, alt, className }) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <div onClick={() => setExpanded(!expanded)} className={`${styles.image} ${expanded && styles["image--expanded"]}`} style={{ '--width': `${width}px` }}>
            <img src={src} alt={alt} className={className} />
        </div>
    )
}
